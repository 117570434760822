const education = [
    {
        title: "React Router & Redux 2019",
        subtitle: "Platzi",
        description: "",
        date: "(2019)",
        icon: "ti-mobile",
    },
    {
        title: "End to End Testing with Cypress",
        subtitle: "Platzi",
        description: "",
        date: "(2021)",
        icon: "ti-settings",
    },
    {
        title: "Backend & Frontend Integration 2020",
        subtitle: "Platzi",
        description: "",
        date: "(2020)",
        icon: "ti-share",
    },
    {
        title: "ECMAScript 6+",
        subtitle: "Platzi",
        description: "",
        date: "(2020)",
        icon: "ti-star",
    },
    {
        title: "Async with JavaScript",
        subtitle: "Platzi",
        description: "",
        date: "(2021)",
        icon: "ti-ruler-alt-2",
    },
    {
        title: "TypeScript | ReactJS | iOnic5",
        subtitle: "Platzi",
        description: "",
        date: "(Actually)",
        icon: "ti-ruler-alt-2",
    },
    {
        title: "Computer Security",
        subtitle: "Platzi",
        description: "",
        date: "(2020)",
        icon: "ti-magnet",
    },
    {
        title: "iOnic 4",
        subtitle: "Platzi",
        description: "",
        date: "(2018)",
        icon: "ti-mobile",
    },
    {
        title: "AWS Cloud Fundamentals Course",
        subtitle: "Platzi",
        description: "",
        date: "(2017)",
        icon: "ti-cloud",
    },
    {
        title: "MYSQL",
        subtitle: "Platzi",
        description: "",
        date: "(Auto-Self from 2008 - Certified in Process)",
        icon: "ti-bar-chart",
    },
    {
        title: "Udemy",
        subtitle: "React Native",
        description: "",
        date: "(Actually)",
        icon: "ti-ruler-alt-2",
    },
    {
        title: "Jorge Tadeo University",
        subtitle: "Media Production | Bogotá",
        description: "-",
        date: "(2008-2010)",
        icon: "ti-crown",
    },
    {
        title: "PHP Advanced",
        subtitle: "Platzi",
        description: "",
        date: "(Auto-Self from 2008 - Certified on 2019)",
        icon: "ti-pin2",
    },
    {
        title: "Javeriana University",
        subtitle: "3D Model and Comubustion | Bogotá",
        description: "",
        date: "(2016-2018)",
        icon: "ti-cup",
    },
    {
        title: "Los Libertadores University",
        subtitle: "Graphic Design | Bogotá",
        description: "",
        date: "(2016-2018)",
        icon: "ti-ruler-alt-2",
    }
];

export default education;